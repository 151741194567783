/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import { loadFonts } from './webfontloader'
import router from '../router'
import { createPinia } from 'pinia'

// import { Amplify } from 'aws-amplify';
import AmplifyVue from '@aws-amplify/ui-vue';
import '@aws-amplify/ui-vue/styles.css';

// import awsExports from '@/aws-exports';

// Amplify.configure(awsExports);

export function registerPlugins (app) {
  loadFonts();

  app
    .use(createPinia())
    .use(router)
    .use(AmplifyVue);
}

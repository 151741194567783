<script setup>
    import router from '@/router'
    ;
    document.addEventListener('DOMContentLoaded', () => {
        const loginForm = document.getElementById('login-form');
        const loginError = document.getElementById('login-error');

        loginForm.addEventListener('submit', (event) => {
            event.preventDefault(); // Prevent the default form submission

            const username = document.getElementById('email').value;
            const password = document.getElementById('password').value;

            // Predefined credentials
            const correctUsername = 'talanoop@amazon.com'; 
            const correctPassword = 'password123'; 

            if (username === correctUsername && password === correctPassword) {
                console.log('Authentication successful');
                // Redirect to another page or show success message
                router.push({ name: 'App' });
            } else {
                console.error('Authentication failed');
                loginError.textContent = 'Login failed. Please check your credentials.';
                // Show error message
            }
        });
    });
</script>

<template>
    <div class="login-container">
        <h2 class="login-title">Login</h2> <!-- Login -->
        <form class="login-form" id="login-form" onsubmit="return false;">
            <input type="email" id="email" placeholder="Email" required>
            <input type="password" id="password" placeholder="Password" required>
            <button type="submit" class="login-btn">LOGIN</button>
            <button type="button" class="signup-btn">SIGN UP</button>
        </form>
        <!-- Error message will be displayed below the form -->
        <div id="login-error" class="error-message"></div>
    </div>
</template>

<style>
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #2c3e50;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

#app {
    width: 50%;
    height: 100%;
}

.chat-container {
  width: 70%; /* Set width to 70% */
  margin: 0 auto; /* Center the container */
  max-width: 700px; /* max-width to prevent it from getting too wide on large screens */
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #333;
}

.login-container {
  background-color: #34495e;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-form {
  display: flex;
  flex-direction: column;
}

input {
  margin-bottom: 20px;
  padding: 15px;
  border: none;
  border-radius: 5px;
}

.login-title {
  color: white; 
  text-align: center;
  margin-bottom: 1em; 
  margin-left: -1ch;
}

.login-btn, .signup-btn {
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-btn {
  background-color: #e67e22;
  color: white;
  margin-bottom: 1em; 
}

.signup-btn {
  background-color: #bdc3c7;
}

.error-message {
  color: #e74c3c; /* Red color for error messages */
  font-size: 14px; 
  text-align: center; 
  margin-top: 10px; 
}


::placeholder {
  color: #95a5a6;
}

input:focus, button:focus {
  outline: none;
}
</style>